<template>
  <v-dialog v-model="dialog" width="800">
    <template #activator="{ on, attrs }">
      <v-btn class="mx-1" v-if="isOperationAdd()" color="primary" text outlined v-bind="attrs" v-on="on" :disabled="isDisabled()">{{ $t("Add DTO's") }}</v-btn>
      <v-btn class="mx-1" v-if="isOperationRemove()" color="red" text outlined v-bind="attrs" v-on="on" :disabled="isDisabled()">{{ $t("Remove DTOs") }}</v-btn>
    </template>
    <v-card :loading="loading">
      <v-form lazy-validation ref="formDTO">
        <v-card-title class="text-h5 mb-5" v-if="isOperationAdd()">{{ $t("Add DTO's") }}</v-card-title>
        <v-card-title class="text-h5 mb-5" v-if="isOperationRemove()">{{ $t("Remove DTOs") }}</v-card-title>
        <v-card-text>
          <v-text-field disabled :label="$t('ID')" v-model="this.id" outlined />
          <v-text-field disabled :label="$t('Title')" v-model="this.title" outlined />
          <v-text-field 
            disabled 
            :label="$t('Assigned DTOs')" 
            v-model="this.assigned_dtos_by_type[this.dto_type_selected]" 
            outlined />
          <v-select
            v-model="yearSelected"
            :items="years"
            :label="$t('Validity Until')"
          >
            <template v-slot:append>
              <v-icon color="primary">mdi-calendar-blank</v-icon>
            </template>
          </v-select>
          <v-select
            v-model="dto_type_selected"
            :items="dto_type_options"
            :label="$t('DTO_type')"
            
          >
          <template v-slot:append>
              <span color="primary"> DTO_TYPE</span>
            </template>
            
          </v-select>
            <v-text-field v-if="isOperationAdd()"
            :label="$t('Number of DTO to assign')" 
            v-model.number="org.DTOtoAssign[dto_type_selected]"
            :rules="addNumberRules"
            type="number" 
            suffix="DTO" 
            step="0.01" 
            min="0.01" 
            :max="this.org.DTO_available[dto_type_selected]" 
            :hint="$t('Max DTOs:', { value: this.org.DTO_available[dto_type_selected] })"
            :disabled="!dto_type_selected"/>
          <v-text-field v-if="isOperationRemove()"
            :label="$t('Number of DTO to remove')" 
            v-model.number="org.DTOtoAssign[dto_type_selected]"
            :rules="removeNumberRules"
            type="number" 
            suffix="DTO" 
            step="0.01" 
            :max="this.DTO_available_proposal[dto_type_selected]" 
            min="0.01" 
            :hint="$t('Max DTOs:', { value: this.DTO_available_proposal[dto_type_selected] })"
            :disabled="!dto_type_selected"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="close">{{ $t('Close') }}</v-btn>
          <v-btn v-if="isOperationAdd()" color="primary" text @click="submit">{{ $t('Assign') }}</v-btn>
          <v-btn v-if="isOperationRemove()" color="red" text @click="submit">{{ $t('Remove') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { sumValues} from '@/helpers/utils.js'

export default {
  name: "OperateDTO",
  props: {
    id: {
      type: Number,
    },
    title: {
      type: String,
    },
    assigned_dtos_by_type:{
      type: Object,
    },
    organization:{
      type: String,
    },
    operation:{
      type: String,
      default: "add"
    },
    status:{
      type: String,
    },
    dto_type_options:{
      type: Array
    },
    observing_runs:{
      type: Array,
    }
  },
  watch: {
    yearSelected: {
      immediate: true,
      handler() {
        this.setValidityDate();
        if(this.isOperationAdd()){
          this.getNumDTOAvailable();
        }else if(this.isOperationRemove()){
          this.getNumDTOAvailableProposal();
        }
      }
    },
    dialog:{
      handler() {
        if(typeof this.$refs.formDTO != "undefined"){
          this.$refs.formDTO.resetValidation();
        }
        if(this.isOperationAdd()){
          this.getNumDTOAvailable();
        }else if(this.isOperationRemove()){
          this.getNumDTOAvailableProposal();
        }
      }
    }
  },
  data: function() {
    return {
      validity:{
        orgId: null,
        year: null,
        start_date: null,
        end_date: null,
        amount: null
      },
      yearSelected: null,
      dto_type_selected: null,
      years: [],
      org:{
        id: null,
        name: null,
        DTO_available: null,
        DTOtoAssign: {},
      },
      addNumberRules: [],
      removeNumberRules: [],
      dialog: false,
      loading: false,
      DTO_available_proposal: null,
      lockedDtosVirtual: null
    }
  },
  created() {
    this.setYearsArray();
    this.setValidityDate();
    this.getOrganizations();
    this.setLockedDtosVirtual();
  },
  mounted() {
    this.activateRules();
  },
  computed: {

  },
  methods: {
    sumValues,
    submit() {
      const data = {
        organization: this.org.id, 
        proposal: this.id,
        amount: this.org.DTOtoAssign[this.dto_type_selected] || 0,
        validity: this.validity.end_date,  // 2023-07-13T00:00:00Z
        assigned_by: this.$store.getters["auth/decodedToken"].user_id, 
      };
      let endpoint = "proposals-assignment/";
      
      data.amount = this.isOperationRemove()? -this.org.DTOtoAssign[this.dto_type_selected] || 0 : this.org.DTOtoAssign[this.dto_type_selected] || 0;

      if(this.isOperationAdd() && this.lockedDtosVirtual>0){
        endpoint = "proposals-assignment/virtual/";
        data.observingRunIds = this.getObservingRunIdList();
      }
      if (this.$refs.formDTO && this.$refs.formDTO.validate()) {
        this.loading = true;

        this.$apiRequest('post', endpoint, { 
          data: data,
        }).then(() => {
          this.dialog = false;
          this.$store.dispatch('alert/alert', {
            type: "info",
            message: this.$t("Proposal assignment succesfully created")
          });
        }).finally(() => {
          this.org.DTO_available[this.dto_type_selected] = this.org.DTO_available[this.dto_type_selected] || 0  - this.org.DTOtoAssign[this.dto_type_selected] || 0 ;
          this.loading = false;
          this.resetForm();
          this.$emit("done");
        })
      }
    },
    getOrganizations() {
      let aux;
      this.loading = true;
      this.org.name = this.organization;
      this.$apiRequest('get', 'organizations/').then((res) => {
        aux = res.data.filter(item => item.name === this.organization);
        if(aux.length>0){
          this.org.id = aux[0].id;
          this.validity.orgId = aux[0].id;
        }
      }).finally(() => {
        if(this.isOperationAdd()){
          this.getNumDTOAvailable();
        }else if(this.isOperationRemove()){
          this.getNumDTOAvailableProposal();
        }
      })
    },
    // Número de DTO's disponibles para la organización del usuario
    getNumDTOAvailable() {
      let dict;

      this.loading = true;
      this.org.DTO_available = {};
      if(this.validity.orgId !== null && this.validity.year !== null){
        this.$apiRequest('get', `proposals-assignment/available/?year=${this.validity.year}`, {}, [404, 403]).then(res => {
          dict = res.data;
        }).finally(() => {
            this.org.DTO_available = dict;
            this.loading = false;
        })
      }
    },
    // Número de DTO's disponibles de una proposal
    getNumDTOAvailableProposal() {
      this.loading = true;
      this.DTO_available_proposal = {};
      if(this.validity.year !== null){
        this.$apiRequest('get', `proposals-assignment/proposal/available/`, 
        {params: { year: this.validity.year, id: this.id }}, [404, 403]).then(res => {
          this.DTO_available_proposal = res.data;
        }).finally(() => {
            this.loading = false;
        })
      }
    },
    setYearsArray(){
      const currentYear = new Date().getFullYear();
      this.years = Array.from({ length: 5 }, (_, i) => currentYear + i);
      this.yearSelected = this.years[0];
    },
    setValidityDate(){
      this.validity.year = this.yearSelected;
      this.validity.start_date = this.yearSelected+"-01-01"
      this.validity.end_date = this.yearSelected+"-12-31T00:00:00Z"
    },
    activateRules() {
      let max = null;
      let min = null;
      let maxOperation= null;
      let addRules = [];
      let removeRules = [];
      let required = v => v != null || this.$t("The field is required");
      let numeric = v =>
        (v != null && /^-?[0-9.]*$/.test(v)) ||
        this.$t("The field must be numeric");
      let positive = v =>
        (v != null && v>0) ||
        this.$t("The field must be positive");
      // let negative = v =>
      //   (v != null && v<0) ||
      //   this.$t("The field must be negative");
      
      if(this.isOperationAdd()){
        max = v =>
          (v != null && v<=this.org.DTO_available[this.dto_type_selected] || 0) ||
          this.$t("Enough DTO", { value: this.org.DTO_available[this.dto_type_selected] || 0 });

        min = v =>
          (v != null && v>=this.lockedDtosVirtual) ||
          this.$t("DTOs blocked without balance", { value: this.lockedDtosVirtual });
      }
      
      if(this.isOperationRemove()){
        maxOperation = v =>
          (v != null && v<=this.DTO_available_proposal[this.dto_type_selected] || 0) ||
          this.$t("Enough DTO", { value: this.DTO_available_proposal[this.dto_type_selected] || 0 });
      }
             

      addRules.push(required, positive, numeric, max, min);
      removeRules.push(required, positive, numeric, maxOperation);
      Object.assign(this.addNumberRules, addRules);
      Object.assign(this.removeNumberRules, removeRules);
    },
    close(){
      this.dialog = false;
      this.resetForm();
    },
    isOperationAdd(){
      return this.operation === "add";
    },
    isOperationRemove(){
      return this.operation === "remove";
    },
    isDisabled(){
      return this.status != 'approved';
    },
    resetForm(){
      this.org.DTOtoAssign = {};
      this.yearSelected = this.years[0];
      this.dto_type_selected = null;
      this.$refs.formDTO.resetValidation();
    },
    setLockedDtosVirtual(){
      if(typeof this.observing_runs !== "undefined"){
        this.lockedDtosVirtual = this.observing_runs.filter(item => item.locked_dtos_virtual > 0).map(item => item.locked_dtos_virtual - item.consumed_dtos).reduce((accum, currVal) => accum+ currVal, 0);
        this.lockedDtosVirtual = this.lockedDtosVirtual.toFixed(4)
      }
    },
    getObservingRunIdList(){
      if(typeof this.observing_runs !== "undefined"){
        return this.observing_runs.filter(item => item.locked_dtos_virtual > 0).map(item => item.id);
      }else{
        return [];
      }
    }

  },
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
