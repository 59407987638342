import i18n from '@/plugins/i18n'

export default {
    PROPOSAL_STATUS_CHOICES: [
        { value: "pending", text: i18n.t('Pending'), color: 'blue-grey', },
        { value: "scientifically_approved", text: i18n.t('Scientifically approved'), color: 'deep-orange', },
        { value: "approved", text: i18n.t('Approved'), color: 'orange', },
        { value: "rejected", text: i18n.t('Rejected'), color: 'red', },
        { value: "closed", text: i18n.t('Closed'), color: 'blue', },
        { value: "archived", text: i18n.t('Archived'), color: 'brown', },
        { value: "cancelled", text: i18n.t('Cancelled'), color: 'indigo', },
    ],
    OBSERVING_RUN_STATUS_CHOICES: [
        { value: "pending", text: i18n.t('Pending'), color: 'blue-grey', },
        { value: "in_progress", text: i18n.t('In progress'), color: 'orange', },
        { value: "done", text: i18n.t('Done'), color: 'green', },
        { value: "cancelled", text: i18n.t('Cancelled'), color: 'red', },
    ],
    OBSERVING_BLOCK_STATUS_CHOICES: [
        { value: "created", text: i18n.t('Created'), color: 'blue-grey draken-1', },
        { value: "scheduled", text: i18n.t('Scheduled'), color: 'blue-grey darken-2', },
        { value: "in_progress", text: i18n.t('In progress'), color: 'blue', },
        { value: "observed", text: i18n.t('Observed'), color: 'green darken-1', },
        { value: "deficient", text: i18n.t('Deficient'), color: 'orange', },
        { value: "not_astrometrizable", text: i18n.t('Not astrometrizable'), color: 'orange', },
        { value: "not_stellar_psf", text: i18n.t('Not stellar PSF'), color: 'pink darken-3', },
        { value: "not_enough_stars", text: i18n.t('Not enough stars'), color: 'deep-purple', },
        { value: "done", text: i18n.t('Done'), color: 'green darken-4', },
        { value: "failed", text: i18n.t('Failed'), color: 'red', },
    ],
    OBSERVING_LINE_STATUS_CHOICES: [
        { value: "pending", text: i18n.t('Pending'), color: 'blue-grey', },
        { value: "exposing", text: i18n.t('Exposing'), color: 'blue', },
        { value: "exposed", text: i18n.t('Exposed'), color: 'green', },
        { value: "observed", text: i18n.t('Observed'), color: 'green darken-1', },
        { value: "waiting_pre-reduction", text: i18n.t('Waiting for pre-reduction'), color: 'green darken-1', },
        { value: "waiting_ext_pre-reduction", text: i18n.t('Waiting for external pre-reduction'), color: 'green darken-1', },
        { value: "pre-reducing", text: i18n.t('Pre-reducing'), color: 'green darken-1', },
        { value: "pre-reduced", text: i18n.t('Pre-reduced'), color: 'green darken-2', },
        { value: "pre-reduction_failed", text: i18n.t('Pre-reduction failed'), color: 'orange', },
        { value: "waiting_reduction", text: i18n.t('Waiting for reduction'), color: 'green darken-2', },
        { value: "reducing", text: i18n.t('Reducing'), color: 'green darken-2', },
        { value: "reduced", text: i18n.t('Reduced'), color: 'green darken-3', },
        { value: "reduction_failed", text: i18n.t('Reduction failed'), color: 'orange', },
        { value: "waiting_post-processing", text: i18n.t('Waiting for post-processing'), color: 'green darken-3', },
        { value: "post-processing", text: i18n.t('Post-processing'), color: 'green darken-3', },
        { value: "post-process_failed", text: i18n.t('Post-process failed'), color: 'orange', },
        { value: "not_astrometrizable", text: i18n.t('Not astrometrizable'), color: 'orange', },
        { value: "not_stellar_psf", text: i18n.t('Not stellar PSF'), color: 'pink darken-3', },
        { value: "not_enough_stars", text: i18n.t('Not enough stars'), color: 'deep-purple', },
        { value: "done", text: i18n.t('Done'), color: 'green darken-4', },
        { value: "failed", text: i18n.t('Failed'), color: 'red', },
    ],
    TELESCOPE_STATUS_CHOICES: [
        { value: "tracking", text: i18n.t('Tracking'), color: 'blue', },
        { value: "slewing", text: i18n.t('Slewing'), color: 'orange', },
        { value: "stopped", text: i18n.t('Stopped'), color: 'error', },
        { value: "parked", text: i18n.t('Parked'), color: 'grey', },
        { value: "still", text: i18n.t('Still'), color: 'grey', },
    ],
    OPEN_CLOSE_CHOICES: [
        { value: "open", text: i18n.t('Open'), color: 'green', },
        { value: "closed", text: i18n.t('Closed'), color: 'orange', },
    ],
    OPERATING_INACTIVE_CHOICES: [
        { value: "exposing", text: i18n.t('Exposing'), color: 'green', },
        { value: "idle", text: i18n.t('Idle'), color: 'blue', },
        { value: "reading", text: i18n.t('Reading'), color: 'orange', },
        { value: "off", text: i18n.t('Off'), color: 'grey', },
    ]
}
